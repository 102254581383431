import styled from 'styled-components';
import DownloadIcon from '../../assets/img/icon-download.svg';

import { breakpointUp } from '../../helpers/breakpoints';

import { Row as RBRow, Container as RBContainer } from 'react-bootstrap';

export const Container = styled(RBContainer)`
    ${breakpointUp.lg`
        width: 1192px;
    `}
`;

export const DownloadLink = styled.a`
    background: url(\"${DownloadIcon}\") no-repeat left center;
    background-size: 35px 35px;
    display: inline-block;
    position: relative;
    padding: 0 0 0 40px;
`;

export const Row = styled(RBRow)`
    .col-mobile-bottom-margin {
        margin-bottom: 32px;

        ${breakpointUp.lg`
            margin-bottom: 0;
        `}
    }

    ul {
        margin: 0;
    }

    li {
        padding-bottom: 16px;
    }
`;
