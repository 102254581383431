import { useCallback, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';

import {
    SET_CURRENT_STEP,
    UPDATE_REPORT_BUILDER_FIELDS,
    SET_UPLOAD_IMAGE_ID,
    SET_UPLOAD_LOGO_IMAGE,
    useAppContext,
    SET_UPLOAD_PDF_ID,
    SET_UPLOAD_PDF,
} from '../../state';

import { nanoid } from 'nanoid';
import { graphQLQuery } from 'services/graphql/query';
import graphQLMutation from 'services/graphql/mutation';
import axios from 'axios';

export const useReportBuilderForm = () => {
    const { state, dispatch } = useAppContext();
    const {
        handleSubmit,
        trigger,
        clearErrors,
        control,
        register,
        setValue,
        getValues,
        watch,
        formState: { isDirty, errors, dirtyFields, isValid },
    } = useForm();
    const [scale, setScale] = useState(100);
    const [formSubmitted, setFormSubmited] = useState(false);
    const [creatingPDF, setCreatingPDF] = useState(false);
    let [reportUrl, setReportUrl] = useState<string | undefined>();

    const file = watch('pdfFile');

    const { currentStep } = state;

    const steps = [
        {
            label: 'Personal details',
            step: 1,
        },
        {
            label: 'Add Logo',
            step: 2,
        },
        {
            label: 'Summary',
            step: 3,
        },
    ];

    useEffect(() => {
        if (file) {
            handlePDFChange(file);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file]);

    const updateFormSubmition = () => {
        setFormSubmited(true);
    };

    const handleNext = async () => {
        const hasRiskRating = await trigger();

        if (!hasRiskRating) {
            return; // Stop proceeding to the next step if form validation fails
        } else {
            const formValues = getValues();

            dispatch({
                type: SET_CURRENT_STEP,
                currentStep: currentStep + 1,
            });
            dispatch({
                type: UPDATE_REPORT_BUILDER_FIELDS,
                reportBuilderData: formValues, // WIP
            });
        }
    };

    const handleBack = () => {
        dispatch({
            type: SET_CURRENT_STEP,
            currentStep: currentStep - 1,
        });
    };

    const handleLogoImageChange = async (logoImage: string) => {
        // Setting the logo value ----
        setValue('logoImage', {
            logo: logoImage,
            logoName: 'Advisers logo',
        });

        // setting a unique ID
        const logoImageId = nanoid();

        if (logoImage) {
            // getting a blob from the logo image ---
            const fileBlob = await fetch(logoImage).then(res => res.blob());
            // creating the input to pass to the query --

            // response is a a upload url
            const data = await graphQLQuery('getUploadLogoUrl', {
                input: {
                    filename: logoImageId,
                },
            });

            if (data?.url) {
                // uploading the logo ---
                await fetch(data.url, {
                    method: 'PUT',
                    headers: { 'Content-Type': fileBlob.type },
                    body: fileBlob,
                });
            }

            dispatch({
                type: SET_UPLOAD_IMAGE_ID,
                uploadImageID: logoImageId,
            });

            dispatch({
                type: SET_UPLOAD_LOGO_IMAGE,
                uploadLogoImage: logoImage,
            });
        }
    };

    const handlePDFChange = async (file: any) => {
        // setting a unique ID
        const pdfFileId = nanoid();

        if (file) {
            // response is a a upload url
            const data = await graphQLQuery('getUploadLogoUrl', {
                input: {
                    filename: pdfFileId,
                },
            });

            if (data?.url) {
                // uploading the logo ---
                await fetch(data.url, {
                    method: 'PUT',
                    headers: { 'Content-Type': file.type },
                    body: file,
                });

                dispatch({
                    type: SET_UPLOAD_PDF_ID,
                    uploadPdfFileID: pdfFileId,
                });

                dispatch({
                    type: SET_UPLOAD_PDF,
                    uploadPDF: file,
                });
            }
        }
    };

    const onSubmit = useCallback(async () => {
        // Retrieve all form values
        if (formSubmitted) {
            const { clientRiskLevel, clientsName, dateprepared, preparedby } = state.reportBuilderData;

            // !* CREATE PDF - WIP
            setCreatingPDF(true);
            const { url } = await graphQLMutation('generateReportBuilderPDF', {
                input: {
                    riskRating: clientRiskLevel,
                    clientName: clientsName ? clientsName : '',
                    date: dateprepared ? dayjs(dateprepared).format('D MMMM YYYY') : '',
                    adviserName: preparedby ? preparedby : '',
                    logoScale: scale ? scale : 0,
                    logoImageId: state.uploadImageID ? state.uploadImageID : undefined,
                    clientPDFId: state.uploadPdfFileID ? state.uploadPdfFileID : undefined,
                },
            });

            setCreatingPDF(false);
            if (url) {
                setReportUrl(url);
            }
            setFormSubmited(false);
        }
    }, [formSubmitted, state.reportBuilderData, scale, state.uploadImageID, state.uploadPdfFileID]);

    const getPDF = useCallback(async () => {
        if (reportUrl) {
            const response = await axios.get(reportUrl, { responseType: 'blob' });
            saveAs(response.data, 'adviser-report.pdf');
        }
    }, [reportUrl]);

    useEffect(() => {
        if (reportUrl) {
            getPDF();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportUrl]);

    useEffect(() => {
        if (formSubmitted) {
            onSubmit();
        }
    }, [formSubmitted, onSubmit]);

    return {
        currentStep,
        steps,
        Controller,
        handleSubmit,
        register,
        control,
        setValue,
        onSubmit,
        getValues,
        handleBack,
        handleNext,
        handleLogoImageChange,
        setScale,
        scale,
        watch,
        updateFormSubmition,
        creatingPDF,
        isDirty,
        errors,
        dirtyFields,
        isValid,
        clearErrors,
        file,
        reportUrl,
    };
};
