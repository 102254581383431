const riskRatings: any = {
    '1': {
        rating: 'Low',
        description:
            "You're likely to be more conservative with your investments. You only want to take a small amount of risk to get potentially modest or relatively stable returns. You understand there may be some short-term changes in value.",
    },
    '2': {
        rating: 'Low-Medium',
        description:
            "You're relatively cautious with your investments. You want the potential of getting reasonable long-term returns and are prepared to accept some risk in doing so. You understand there may be some frequent but small changes in value.",
    },
    '3': {
        rating: 'Medium',
        description:
            "You have a balanced approach to risk. You don't look for risky investments, but you don't avoid them either.  You're prepared to accept fluctuations in the value of your investments to try and get potentially better long-term returns.  You understand that the value of your investments might change frequently and sometimes significantly.",
    },
    '4': {
        rating: 'Medium-High',
        description:
            "You're comfortable taking some investment risk to get potentially better higher long-term returns, even if that means there might be times when you're getting potentially lower returns. You understand the value of your investments are likely to change frequently and often significantly.",
    },
    '5': {
        rating: 'High',
        description:
            "You're very comfortable taking investment risk. You're aiming for potentially high long-term returns and are less concerned if the value of your investments go up and down over the short or medium term. You understand that the value of your investments is likely to change very frequently and significantly.",
    },
};

export default riskRatings;
