import { useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { graphQLQuery } from '../../services/graphql/query';
import { SET_CURRENT_QUESTION, SET_QUESTIONS, UPDATE_ANSWERS, useAppContext } from '../../state';
import { logAnalyticsEvent } from 'services/analytics';

export const useQuestionForm = () => {
    const { state, dispatch } = useAppContext();
    const navigate = useNavigate();
    const isLastQuestion = state?.currentQuestion === state?.questions.length;

    useEffect(() => {
        if (state?.questions.length && state?.currentQuestion) {
            logAnalyticsEvent({ action: `Question ${state.currentQuestion}`, label: `Display` });
        }
    }, [state.questions, state.currentQuestion]);

    useEffect(() => {
        async function fetchQuestionsData() {
            try {
                const questions = await graphQLQuery('getQuestions');
                dispatch({ type: SET_QUESTIONS, questions });
            } catch {
                navigate('/error');
            }
        }
        fetchQuestionsData();
    }, [dispatch, navigate]);

    useEffect(() => {
        if (state.answers.length > 0 && state.answers.length === state.questions.length) {
            navigate('/sustainable-investing');
        }
    }, [state, navigate]);

    const question = state?.questions ? state.questions[state.currentQuestion - 1] : null;

    const { handleSubmit, register, formState, reset } = useForm();
    const onSubmit = useCallback(
        async ({ answer }: any) => {
            reset();
            dispatch({
                type: UPDATE_ANSWERS,
                answer: { questionId: parseInt(state.currentQuestion, 10), responseId: parseInt(answer, 10) },
            });
            dispatch({
                type: SET_CURRENT_QUESTION,
                currentQuestion: Math.min(state.currentQuestion + 1, state.questions.length),
            });
        },
        [state, dispatch, reset]
    );

    const buttonLabel = isLastQuestion ? 'Submit' : 'Next question';

    return {
        handleSubmit,
        register,
        formState,
        question,
        buttonLabel,
        onSubmit,
    };
};
