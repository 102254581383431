export const esgQuestionsData = [
    {
        id: '1',
        answers: [
            {
                id: '1',
                text: 'Yes',
                question:
                    'Please specify the minimum percent of your overall investment you would like to invest in environmentally sustainable investments:',
            },
            {
                id: '2',
                text: 'No',
            },
        ],
        text: 'I would like to invest in “environmentally sustainable investments” (according to the EU taxonomy regulation)',
        accordionData: {
            id: '2',
            accordionTitle: 'What do “environmentally sustainable investments” mean?',
            accordionContent:
                '“Sustainable investments” are Article 9 investments according to the Sustainable Finance Disclosure Regulation.',
            accordionContentTwo: '',

            accordionList: [
                'Article 9 investments are funds with a specific environmental or social objective',
                'They can therefore have a broader focus than “environmentally sustainable investments”',
                'Article 9 funds are also known as “dark green” funds',
            ],
        },
    },
    {
        id: '2',
        answers: [
            {
                id: '1',
                text: 'Yes',
                question:
                    'Please specify the minimum percent of your overall investment you would like to invest in sustainable investments:',
            },
            {
                id: '2',
                text: 'No',
            },
        ],
        text: 'I want to invest in “sustainable investments” (according to the EU Disclosure regulation).',
        accordionData: {
            id: '1',
            accordionTitle: 'What do the “sustainable investments” mean?',
            accordionContent:
                'Investments that are environmentally related are sustainable e.g., climate protection, water protection, forest protection, biodiversity) and/or address social criteria (e.g., fair pay, fair working conditions, fair supply chains) and follow good corporate governance (e.g., fair tax payments, no corruption). Sustainable Investing means investing in companies that contribute to an environmental or social objective while ensuring that the investment does not significantly harm any other environmental or societal objective under the EU Taxonomy. It also requires companies to follow good governance practices.',

            accordionContentTwo:
                'You may also come across the term “ESG”. It refers to the environmental, social, and governance factors used to evaluate companies and funds during the investment process. ESG investing can be achieved by applying exclusions, limiting ESG risk, seeking ESG opportunities, practicing active ownership, targeting sustainability themes, or assessing impact',

            accordionList: [],
        },
    },
    {
        id: '3',
        answers: [
            {
                id: '1',
                text: 'Yes',
            },
            {
                id: '2',
                text: 'No',
            },
        ],
        text: 'I want to invest in assets that reduce negative impacts on the environment (“just avoid harm”).',
        accordionData: {
            id: '3',
            accordionTitle: 'What does “just avoid harm” mean?',
            accordionContent:
                'Investments that consider the negative impacts of the investment strategy on ESG factors may also be referred to as Principal Adverse Impacts (PAIs).',
            accordionContentTwo:
                'PAIs refers to negative ESG indicators that are taken into consideration. Examples of what you might expect to see are greenhouse gas emissions, carbon footprint, hazardous waste, water emissions, negative impacts on areas of biodiversity.',

            accordionList: [],
        },
    },
];
