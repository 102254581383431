import { useEffect } from 'react';
import { H3 } from 'components/ui/Typography';
import { StyledStepTwo } from './styles';
import ImageUpload from 'components/ImageUpload';
import { logAnalyticsEvent } from 'services/analytics';

function StepTwo({ control, Controller, setScale, scale, watch, handleLogoImageChange }: any) {
    const logoImage = watch('logoImage');

    useEffect(() => {
        logAnalyticsEvent({ action: `Report builder 2`, label: `Display` });
    }, []);

    return (
        <StyledStepTwo>
            <H3>Add Logo</H3>
            <Controller
                name="logoImage"
                control={control}
                // rules={{ required: true }}
                render={() => (
                    <ImageUpload
                        logoImage={logoImage}
                        scale={scale}
                        id="logo"
                        onChange={handleLogoImageChange}
                        onScaleChange={(scale: number) => {
                            setScale(scale);
                        }}
                        imageUploadHelpText="Drag a JPG or PNG under 5mb here to upload your logo."
                        scalingHelpText="To achieve the best results, you can scale your logo to fit your report."
                        maximumFileSize={5000000}
                        resizedImageSize={800}
                    />
                )}
            />
        </StyledStepTwo>
    );
}

export default StepTwo;
