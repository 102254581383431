import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import reportBuilder from 'assets/img/reportBuilder.png';

import { Container, Row } from './styles';
import { H2, H3, P } from 'components/ui/Typography';
import Card from 'components/ui/Card';
import Button from 'components/ui/Button';
import { CardTitle, CardContent, CardFlex, CardContentContainer } from 'components/ui/Card/styles';
import { List, ListItem, ListLabel } from 'components/ui/List';
import { homeContent } from 'data/content';
import ButtonLink from 'components/ui/ButtonLink';
import { DownloadLink } from './styles';
import { logAnalyticsEvent } from 'services/analytics';
import { useEffect } from 'react';

function Home() {
    useEffect(() => {
        logAnalyticsEvent({ action: 'Landing page', label: 'Display' });
    }, []);

    return (
        <Container>
            <Row>
                <Col sm={12} lg={8} className="mx-auto">
                    <H2 className="mb-3 mt-5">{homeContent.title}</H2>
                    <P className="mb-1">{homeContent.description}</P>
                    <List>
                        {homeContent.list.map((item, index) => (
                            <ListItem key={index}>
                                <img src={item.icon} alt={item.iconAltText} />
                                <ListLabel>
                                    {item.text}{' '}
                                    {item.id === 3 && (
                                        <a target="_blank" rel="noreferrer" href={item.link}>
                                            {item.linkText}
                                        </a>
                                    )}
                                </ListLabel>
                            </ListItem>
                        ))}
                    </List>
                    <P className="mb-1">
                        <span>{homeContent.listSuffixText} </span>
                        <DownloadLink target="_blank" href={homeContent.listSuffixLinkUrl}>
                            {homeContent.listSuffixLinkText}
                        </DownloadLink>
                    </P>
                    <H3>{homeContent.headingOne}</H3>
                </Col>
            </Row>
            <Row className="mt-2 mt-lg-4">
                {homeContent.cards.map((card, index) => (
                    <Col key={index} sm={12} lg={6} className="mb-3 mb-lg-0">
                        <Card>
                            <CardFlex>
                                <img src={card.icon} alt={card.iconAltText} />
                                <CardContentContainer>
                                    <CardTitle className="mb-2">{card.title}</CardTitle>
                                    <CardContent>
                                        <P>{card.paragraph1}</P>
                                        <br />
                                        {card.paragraph2 && (
                                            <P>
                                                {card.paragraph2}{' '}
                                                {card.paragraphLinkText && (
                                                    <a
                                                        onClick={() =>
                                                            logAnalyticsEvent({
                                                                action: 'Landing page',
                                                                label: 'Download questionnaire as a pdf',
                                                            })
                                                        }
                                                        rel="noreferrer"
                                                        href={card.paragraphLink}
                                                        target="_blank"
                                                    >
                                                        {card.paragraphLinkText}
                                                    </a>
                                                )}
                                            </P>
                                        )}
                                    </CardContent>
                                    {card.route ? (
                                        <ButtonLink>
                                            <div
                                                onClick={() =>
                                                    logAnalyticsEvent({
                                                        action: `Landing page`,
                                                        label: `Launch Risk Profiler`,
                                                    })
                                                }
                                            >
                                                <Link to={card.route}>{card.buttonText}</Link>
                                            </div>
                                        </ButtonLink>
                                    ) : (
                                        <ButtonLink>
                                            <a
                                                onClick={() =>
                                                    logAnalyticsEvent({
                                                        action: `Landing page`,
                                                        label: `Email the link`,
                                                    })
                                                }
                                                href={card.link}
                                            >
                                                {card.buttonText}
                                            </a>
                                        </ButtonLink>
                                    )}
                                </CardContentContainer>
                            </CardFlex>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Row className="my-3">
                <Col sm={12} lg={8} className="mx-auto">
                    <H3>{homeContent.headingTwo}</H3>
                </Col>
            </Row>
            <Row className="mb-5">
                <Col lg={12}>
                    <Card className="p-4">
                        <div className="d-flex flex-wrap">
                            <Col
                                xs={{ order: 'last', span: 12 }}
                                lg={{ order: 'first', span: 6 }}
                                className="pb-4 pb-lg-0"
                            >
                                <CardTitle className="mb-2">{homeContent.reportBuilderCard.title}</CardTitle>
                                <P>{homeContent.reportBuilderCard.description}</P>
                                <List>
                                    {homeContent.reportBuilderCard.list.map((item, index) => (
                                        <ListItem key={index}>
                                            <img src={item.icon} alt={item.iconAltText} />
                                            <ListLabel>{item.text}</ListLabel>
                                        </ListItem>
                                    ))}
                                </List>
                                <Link to={'report-builder'}>
                                    <Button
                                        onClick={() =>
                                            logAnalyticsEvent({ action: `Landing page`, label: `Build the report` })
                                        }
                                        secondary
                                    >
                                        {homeContent.reportBuilderCard.buttonText}
                                    </Button>
                                </Link>
                            </Col>
                            <Col xs={{ order: 'first', span: 12 }} lg={{ order: 'last', span: 6 }}>
                                <img src={reportBuilder} alt="mobile screen and laptop" />
                            </Col>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Home;
