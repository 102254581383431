import { useEffect } from 'react';
import { H2, H3, P } from 'components/ui/Typography';
import { StyledStepThree } from './styles';
import { useReportBuilderData } from './hooks';
import dayjs from 'dayjs';
import { logAnalyticsEvent } from 'services/analytics';

function StepThree() {
    const { clientsName, clientRiskLevel, dateprepared, preparedby, pdfFile, logoImage } = useReportBuilderData();

    useEffect(() => {
        logAnalyticsEvent({ action: `Report builder 3`, label: `Display` });
    }, []);

    return (
        <StyledStepThree>
            <H2>Summary</H2>
            <H3>Client details</H3>
            <P>Client's name</P>
            <P className="navy text-padding">{clientsName ? clientsName : 'Not entered'}</P>

            <P>Client's risk level</P>
            <P className="navy text-padding">{clientRiskLevel && clientRiskLevel}</P>

            <P>Client's Risk Questionnaire results</P>

            {pdfFile ? (
                <P className="navy text-padding"> {pdfFile.path} - uploaded</P>
            ) : (
                <P className="text-padding navy">Not provided</P>
            )}

            <H3>Adviser details</H3>
            <P>Prepared by</P>
            <P className="text-padding navy">{preparedby ? preparedby : 'Not entered'}</P>

            <P>Date prepared</P>
            {dateprepared && <P className="text-padding navy">{dayjs(dateprepared).format('D MMMM YYYY')}</P>}

            <H3>Logo</H3>

            {logoImage?.logo ? (
                <>
                    <P>Uploaded logo file</P>
                    <img className="logo" src={logoImage.logo} alt={logoImage.logoName} />
                </>
            ) : (
                <P className="text-padding navy">Not provided</P>
            )}
        </StyledStepThree>
    );
}

export default StepThree;
