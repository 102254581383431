import { logAnalyticsEvent } from 'services/analytics';
import DeleteIcon from './DeleteIcon';
import { useImageUpload } from './hooks';
import {
    DeleteButton,
    FileUpload,
    FileUploadIcon,
    Image,
    ImagePreview,
    ImageScaling,
    ScalingHelpText,
    Separator,
    Slider,
    SliderLabel,
    UploadButton,
    UploadMessage,
    Wrapper,
} from './styles';

interface ImageUploaderProps {
    scale?: number;
    logoImage?: {
        logo?: '';
    };
    onChange?: Function;
    onScaleChange?: Function;
    id?: string;
    imageUploadHelpText?: string;
    scalingHelpText?: string;
    maximumFileSize?: number;
    resizedImageSize?: number;
}

const ImageUploader = ({
    scale,
    logoImage,
    onChange,
    onScaleChange,
    id,
    imageUploadHelpText = 'Drag a JPG, PNG or SVG under 5mb here to upload your logo.',
    scalingHelpText,
    maximumFileSize,
    resizedImageSize,
    ...otherProps
}: ImageUploaderProps) => {
    const {
        sliderValue,
        handleSliderChange,
        handleSliderAfterChange,
        dataURL,
        getRootProps,
        getInputProps,
        resetImageUpload,
    } = useImageUpload({
        onChange,
        onScaleChange,
        maximumFileSize,
        resizedImageSize,
    });

    return (
        <Wrapper {...otherProps}>
            {dataURL || logoImage?.logo ? (
                <div className="image-preview-wrapper">
                    <ImagePreview className="image-preview">
                        <DeleteButton
                            type="button"
                            title="Remove logo"
                            onClick={() => {
                                resetImageUpload();
                                logAnalyticsEvent({ action: `Report builder 2`, label: `Delete image button` });
                            }}
                        >
                            <DeleteIcon />
                        </DeleteButton>
                        <Image
                            alt="Logo"
                            src={dataURL ? dataURL : logoImage?.logo}
                            style={{ width: `${50 * (sliderValue / 100)}%` }}
                        />
                    </ImagePreview>

                    <ImageScaling className="image-scaling">
                        <ScalingHelpText className="scaling-help-text">{scalingHelpText}</ScalingHelpText>

                        <SliderLabel>
                            Logo scale <strong>{scale ? scale : sliderValue}%</strong>
                        </SliderLabel>

                        <Slider
                            value={scale ? scale : sliderValue}
                            min={50}
                            max={150}
                            className="horizontal-slider"
                            thumbClassName="slider-thumb"
                            trackClassName="slider-track"
                            onChange={value => handleSliderChange(value as number)}
                            onAfterChange={value => handleSliderAfterChange(value as number)}
                        />
                    </ImageScaling>
                </div>
            ) : (
                <FileUpload className="file-upload-wrapper" {...getRootProps()}>
                    <input type="file" id={id} {...getInputProps()} />

                    <FileUploadIcon />

                    <UploadMessage>{imageUploadHelpText}</UploadMessage>

                    <Separator>OR</Separator>

                    <UploadButton
                        onClick={() => logAnalyticsEvent({ action: `Report builder 2`, label: `Browse file button` })}
                        secondary
                        type="button"
                    >
                        Browse files
                    </UploadButton>
                </FileUpload>
            )}
        </Wrapper>
    );
};

export default ImageUploader;
