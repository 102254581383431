import { OverlayTrigger } from 'react-bootstrap';
import ToolTipSVG from './ToolTopSVG';
import { ReportBuilderToolTip } from './styles';
import { logAnalyticsEvent } from 'services/analytics';

interface ToolTipInfoProps {
    tooltipText: string;
}

export const ToolTipInfo = ({ tooltipText }: ToolTipInfoProps) => {
    const tooltip = <ReportBuilderToolTip id="tooltip">{tooltipText}</ReportBuilderToolTip>;

    return (
        <OverlayTrigger placement="top" overlay={tooltip}>
            <span
                onMouseEnter={() => logAnalyticsEvent({ action: `Report builder 1`, label: `Risk level tooltip` })}
                style={{
                    padding: '5px',
                    marginLeft: '5px',
                    cursor: 'pointer',
                }}
            >
                <ToolTipSVG />
            </span>
        </OverlayTrigger>
    );
};
