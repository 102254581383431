import { useAppContext } from 'state';

export const useReportBuilderData = () => {
    const { state } = useAppContext();

    const { reportBuilderData } = state;
    const { clientsName, clientRiskLevel, dateprepared, preparedby, pdfFile, logoImage } = reportBuilderData;

    return {
        clientsName,
        clientRiskLevel,
        dateprepared,
        preparedby,
        pdfFile,
        logoImage,
    };
};
